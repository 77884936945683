const navConfig = [
  {
    title: "Purchase Center",
    route: "/purchase-center",
    icon: "AiFillControl",
  },
  {
    title: "Mobile Purchase Unit",
    route: "/mobile-purchase-unit",
    icon: "AiFillControl",
  },
  //   {
  //     title: "Users",
  //     icon: "FaUserAlt",
  //     // route: '/users',
  //     children: [
  //       {
  //         title: "Users",
  //         icon: "FaUserAlt",
  //         route: "/users",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Projects",
  //     // route: '/dashboard',
  //     icon: "AiFillFolder",
  //     children: [
  //       {
  //         title: "Projects List",
  //         icon: "AiFillFolder",
  //         route: "/projects",
  //       },
  //       {
  //         title: "Add New Projects",
  //         icon: "AiFillFolderAdd",
  //         route: "/create-project",
  //       },
  //     ],
  //   },
];

export default navConfig;

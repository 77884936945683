import Joi from "joi-browser";

// obj is a  key value pair to be validated

//To Validate Object

export const validate = (data, schema) => {
  const result = Joi.validate(data, schema, { abortEarly: false });

  if (!result.error) return null;

  const newError = {};
  result.error.details.map((err) => {
    return (newError[err.path[0]] = err.message);
  });

  return newError;
};

//For Validating whole form
// export const validate = (obj, schema) => {
//   const options = { abortEarly: false };
//   const { error } = Joi.validate(obj, schema, options);
//   if (!error) return null;

//   const errors = {};
//   for (let items of error.details) {
//     errors[items.path[0]] = items.message;
//   }

//   return errors;
// };

// //For validating a property
// const validateProperty = (obj, schema) => {
//   const { error } = Joi.validate(obj, schema);
//   return error ? error.details[0].message : null;
// };

// //Validation function that expects currentTarget and schema to validate
// export const validation = (input, schema) => {
//   let errors = {};
//   const { name } = input;
//   const obj = { [name]: input.value };
//   const propertySchema = { [name]: schema[name] };

//   const errorMessage = validateProperty(obj, propertySchema);

//   if (errorMessage) errors[name] = errorMessage;
//   else delete errors[name];

//   return errors;
// };

// export const onChange = (input, schema, form) => {
//   const { name } = input;
//   const errors = validation(input, schema);
//   const newFormState = { ...form };
//   newFormState[name] = input.value;
//   console.log(newFormState, "new");

//   return { newFormState, errors };
// };

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import styles from "./dashboardStyle";

import Danger from "./Danger";

import Card from "../cardWithIcon/Card.js";
import CardHeader from "../cardWithIcon/CardHeader.js";
import CardIcon from "../cardWithIcon/CardIcon.js";
import CardBody from "../cardWithIcon/CardBody.js";
import CardFooter from "../cardWithIcon/CardFooter.js";

const useStyles = makeStyles(styles);

const CardWithIcon = ({
  Icon,
  title,
  iconColor,
  caption,
  FooterIcon,
  FooterText,
  footerIconColor,
}) => {
  const classes = useStyles();

  return (
    <Card style={{ background: iconColor }}>
      <CardHeader color="warning" stats icon>
        <CardIcon>
          {/* <Icon>content_copy</Icon> */}
          <Icon />
        </CardIcon>
        <p className={classes.cardCategory}>{title}</p>
        <h3 className={classes.cardTitle}>{caption}</h3>
      </CardHeader>
      <CardFooter stats={FooterIcon && FooterText}>
        <div className={classes.stats}>
          <Danger color={footerIconColor}>
            {FooterIcon && <FooterIcon />}
          </Danger>
          <Typography variant="caption">{FooterText && FooterText}</Typography>
        </div>
      </CardFooter>
    </Card>
  );
};

export default CardWithIcon;

import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useHistory, Redirect } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import navConfig from "../utils/SidebarConfig";
import { Link } from "react-router-dom";
import { Popover, Paper } from "@material-ui/core";
import * as ReactIcons from "react-icons/all";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

const drawerWidth = 240;

const styles = {
  link: {
    textDecoration: "none",
    color: "black",
  },
  iconStyle: {
    fontSize: "18px",
    // color: 'black'
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: "white",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(11) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9.5) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarDropdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "white",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  typography: {
    minWidth: 106,
    //minHeight:200,
    padding: theme.spacing(2),
  },
  paddingTB: {
    width: "100%",
    margin: theme.spacing(1, 0, 1, 0),
  },
  expand: {
    // background: 'white',
    zIndex: 2,
  },
  overflow: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  listItemText: {
    fontSize: "14px",
    fontWeight: "inherit",
    // fontWeight: '500',
    marginTop: theme.spacing(0.5),
  },
  logoStyle: {
    marginRight: "auto",
    marginLeft: theme.spacing(10),
  },
  active: {
    fontWeight: "bold",
    background: "lightgrey",
    color: theme.palette.primary.main,
  },
}));

export default function Layout({ children, props }) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [expandList, setExpandList] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sidebarData, setSidebarData] = useState(null);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setExpandList([]);
  };

  useEffect(() => {
    // const token = localStorage.getItem("auth-token");
    // setToken(token);
    setSidebarData(navConfig);
    // setSidebarData(JSON.parse(getUser().sidebarJson));
    const list = [];
    navConfig.forEach((item) => list.push(false));
    // JSON.parse(getUser().sidebarJson).forEach((item) => list.push(false));
    setExpandList(list);
  }, []);

  const handleLogout = () => {
    // Logout();
    localStorage.removeItem("vavaCarsToken");
    window.location = "/";
    // history.replace("/");
  };

  const handleCollapse = (index) => {
    const list = [...expandList];
    list[index] = !list[index];
    setExpandList(list);
  };

  const renderIcon = (icon) => {
    // if(ReactIcons[icon]){
    return React.createElement(
      ReactIcons[icon] || ReactIcons["AiOutlineFileUnknown"],
      { style: styles.iconStyle }
    );
    // }
    // return  React.createElement(ReactIcons['AiOutlineFileUnknown'], {style: styles.iconStyle})
  };

  return (
    <div className={classes.root}>
      {localStorage.getItem("vavaCarsToken") === null && <Redirect to="/" />}
      <Popover
        className={classes.accountPopover}
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper className={classes.typography}>
          <Typography>Profile</Typography>
          <Divider className={classes.paddingTB} />
          <Typography>Settings</Typography>
        </Paper>
      </Popover>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {props && props.pageName}
          </Typography>
          <span style={{ flex: 1 }} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {/* <img
            className={classes.logoStyle}
            height={40}
            alt="Logo"
            // src={require("../assets/images/Logo-New.png")}
          /> */}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {sidebarData &&
            sidebarData.map((item, index) => {
              if (item.children) {
                return (
                  <React.Fragment key={index}>
                    <abbr style={{ textDecoration: "none" }} title={item.title}>
                      <ListItem button onClick={() => handleCollapse(index)}>
                        <ListItemIcon style={{ minWidth: "33px" }}>
                          {/* {React.createElement(ReactIcons[item.icon || 'AiOutlineFileUnknown'], {style: styles.iconStyle})} */}
                          {renderIcon(item.icon)}
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary={item.title}
                        />
                        {expandList[index] ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                    </abbr>

                    <Collapse
                      in={expandList[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {item.children.map((child, idx) => (
                          <Link style={styles.link} to={child.route} key={idx}>
                            <abbr
                              style={{ textDecoration: "none" }}
                              title={child.title}
                            >
                              <ListItem
                                button
                                className={
                                  window.location.pathname.includes(child.route)
                                    ? clsx(classes.nested, classes.active)
                                    : classes.nested
                                }
                              >
                                <ListItemIcon style={{ minWidth: "33px" }}>
                                  {renderIcon(child.icon)}
                                </ListItemIcon>
                                <ListItemText
                                  classes={{ primary: classes.listItemText }}
                                  primary={child.title}
                                />
                              </ListItem>
                            </abbr>
                          </Link>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              } else {
                return (
                  <Link style={styles.link} to={item.route} key={index}>
                    <abbr style={{ textDecoration: "none" }} title={item.title}>
                      <ListItem style={{}} button>
                        <ListItemIcon
                          style={{ minWidth: "33px" }}
                          onMouseEnter={() =>
                            open ? null : console.log("Hover")
                          }
                        >
                          {/* {React.createElement(ReactIcons[item.icon || 'AiOutlineFileUnknown'], {style: styles.iconStyle})} */}
                          {renderIcon(item.icon)}
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.listItemText }}
                          primary={item.title}
                        />
                      </ListItem>
                    </abbr>
                  </Link>
                );
              }
            })}
        </List>

        {/* <List>
          <ListItem onClick={() => console.log('aa')} button>
              <ListItemIcon>
                <AiFillControl style={{fontSize:'20px'}}/>
              </ListItemIcon>
              <ListItemText primary={"IAM"} />
            </ListItem>
        </List> */}

        <div style={{ position: "absolute", bottom: "10px", width: "100%" }}>
          <Divider style={{ width: "100%" }} />
          <List>
            <ListItem onClick={() => handleLogout()} button key={"Logout"}>
              <ListItemIcon style={{ minWidth: "33px" }}>
                {/* <BiLogOutCircle style={{fontSize:'20px'}}/> */}
                {/* {React.createElement(ReactIcons['BiLogOutCircle'], {style: styles.iconStyle})} */}
                {renderIcon("BiLogOutCircle")}
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary={"Logout"}
              />
            </ListItem>
          </List>
        </div>
      </Drawer>
      {/* } */}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

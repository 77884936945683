import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./Screens/Login/Login";
import PurchaseCenter from "./Screens/Dashboard/PurchaseCenter";
import MobilePurchaseUnit from "./Screens/Dashboard/MobilePurchaseUnit";
export default function router() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/purchase-center" component={PurchaseCenter} />
      <Route
        exact
        path="/mobile-purchase-unit"
        component={MobilePurchaseUnit}
      />
    </Switch>
  );
}

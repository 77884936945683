import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

export default function App(props) {
  const options = {
    chart: {
      type: "bar",
      width: 270,
    },
    title: {
      text: "Title",
    },
    colors: [""],
    xAxis: {
      categories: ["C1", "C2", "C3", "C4", "C5", "C6", "C7", "C8"],
      title: {
        text: null,
      },
      labels: {
        enabled: false,
        align: "right",
        padding: -20,
      },
    },

    yAxis: {
      // min: 0.0,
      // max: 25.0,
      title: {
        text: null,
        align: "high",
      },
      labels: {
        overflow: "allow",
      },
      opposite: true,
      gridLineWidth: 0,
      accessibility: {
        enabled: false,
      },
      // visible: false,
      stackLabels: {
        style: {
          color: "black",
          fontSize: "15",
        },
        enabled: true,
        // verticalAlign: "top",
      },
    },
    tooltip: {
      // enabled: false
      valueSuffix: "",
    },
    // plotOptions: {
    //   column: {
    //     stacking: "normal",
    //     pointPadding: 0,
    //     groupPadding: 0,
    //     dataLabels: {
    //       enabled: false,
    //     },
    //   },
    // },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          align: "right",
        },
      },
      column: {
        stacking: "normal",
        // pointPadding: 0,
        // groupPadding: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    legend: {
      // layout: "vertical",
      // align: "left",
      // verticalAlign: "middle",
      // floating: true,
      // x: -40,
      // y: 80,
      // floating: true,
      // borderWidth: 1,
      // backgroundColor:
      //   Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      // shadow: true
      // enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "",
        data: [125, 84, 143, 71, 31, 207, 112, 159],
        dataLabels: {
          align: "left",
          padding: 0,
        },
        // color: "rgb(158, 159, 163)",
        // colors: ["#81b941", "#ffc000", "#00b0f0", "#c3e1fd"],
      },
    ],
  };
  const {
    title,
    data,
    color,
    min,
    max,
    showLabels,
    categories,
    type,
    width,
    legend,
  } = props;
  const [state, setState] = useState(null);

  useEffect(() => {
    const temp = { ...options };
    temp.title.text = title;
    temp.series[0].data = data;
    temp.series[0].name = legend || "";
    temp.legend.enabled = Boolean(legend);
    temp.colors = color;
    temp.xAxis.categories = categories;
    if (showLabels) {
      temp.xAxis.labels.enabled = showLabels;
      temp.chart.type = type;
      temp.chart.width = width;
    }
    setState(temp);
  }, []);

  return state && <HighchartsReact highcharts={Highcharts} options={state} />;
}

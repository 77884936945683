import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, Snackbar } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Toast = ({ open, handleClose, text, color, ...rest }) => {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //     setOpen(true);
  // };

  // const handleClose = (event, reason) => {
  //     if (reason === 'clickaway') {
  //     return;
  //     }

  //     setOpen(false);
  // };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={2000}
      onClose={handleClose}
      {...rest}
    >
      <Alert onClose={handleClose} severity={color}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default Toast;

import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Layout from "../../Layout/Layout";
import clsx from "clsx";
import BarChart from "../../Components/BarChart";
import Card from "../../Components/cardWithIcon/CardWithIcon";
import Dropdown from "../../Components/Dropdown/Dropdown";
import { FaRegCalendarCheck } from "react-icons/fa";
import {
  getAttributesScores,
  getBannerValues,
  fetchLocations,
  fetchVavaCarsPersons,
  fetchLocationWiseScore,
  fetchPersonWiseScore,
  fetchRespondentName,
  fetchRespondentImage,
  fetchMonth
} from "../../Services/dashboard";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(2),
    border: "1px solid lightgrey",
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(5, 0),
    width: "100%",
  },
  heading: {
    padding: theme.spacing(3),
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: "white",
    width: "100%",
    background: theme.palette.primary.main,
  },
  dropdownContainer: {
    padding: theme.spacing(3),
    width: "20%",
  },
  dropdownContainer1: {
    padding: theme.spacing(3),
    width: "40%",
  },
  grid: {
    padding: theme.spacing(3),
  },
  paddingL: {
    //    marginLeft:theme.spacing(2)
  },
  paddingR: {
    //   marginRight:theme.spacing(2)
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const banners = ["VavaCars", "CarFirst", "Dealer", "Toyota Sure"];
  const colors = ["#81b941", "#ffc000", "#00b0f0", "#c3e1fd"];
  const grids = [4, 3, 3, 2];
  const [bannerValues, setBannerValues] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [locations, setLocations] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState("All");
  const [months, setMonths] = useState([{value:"All"}]);
  const [selectedMonths, setSelectedMonths] = useState("All");
  const [monthsData,setMonthsData] = useState(null);
  const [persons, setPersons] = useState(null);
  const [Respondent, setRespondent] = useState(null);
  const [selectedPersons, setSelectedPersons] = useState(null);
  const [selectedRespondent, setSelectedRespondent] = useState(null);
  const [RespondentData,setRespondentData] = useState(null);
  const [personsData,setPersonsData] = useState(null);
  const [locationData,setLocationData] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    getData();
    getLocations();
    getPersons();
    getMonths()
    getRespondent();
  }, []);

  useEffect(() => {
    if(selectedLocations){
      getLocationWiseScore();
    }
  }, [selectedLocations]);

  useEffect(() => {
    if(selectedPersons){
      getPersonWiseScore();
    }
  }, [selectedPersons]);

  useEffect(() => {
    if(selectedRespondent){
      getImages();
    }
  }, [selectedRespondent]);

  useEffect(() => {
    if(selectedMonths){
      setBannerValues(null)
      setAttributes(null)
      getData();
    }
  },[selectedMonths])

  const getImages =async () => {
    try {
  
      const res = await fetchRespondentImage(selectedMonths, selectedRespondent)
      console.log(res, "RES")
      const idata = JSON.parse(res)
      setImage(idata);
      console.log(idata, "IDATA")
    }
    catch(error){
      console.log(error)
    }
  }


  // const getMonthWiseScore = async () => {
  //   try{
  //     const res = await fetchMonth(selectedMonths);
  //     console.log("Month Wise Score", JSON.parse(res));
  //     const mdata = JSON.parse(res);
  //     setMonthsData(mdata);
  //   }
  //   catch(error){
  //       console.log(error);
  //   }
  // }
  const getLocationWiseScore = async () => {
    try {
      const res = await fetchLocationWiseScore(selectedLocations, selectedMonths);
      console.log("Location Wise Score", JSON.parse(res));
      const ldata = JSON.parse(res).attributes;
      setLocationData(ldata);
    } catch (error) {
      console.log(error);
    }
  };

  const getPersonWiseScore = async () => {
    try {
      const res = await fetchPersonWiseScore(selectedPersons, selectedMonths);
      console.log("Person Wise Score", JSON.parse(res));
      const pData = JSON.parse(res).attributes;
      setPersonsData(pData);
    } catch (error) {
      console.log(error);
    }
  };

  // const getRespondentName = async () => {
  //   try {
  //     const res = await fetchRespondentName();
  //     console.log("Respondent Wise Score", JSON.parse(res));
  //     const rData = JSON.parse(res).Names;
  //     setRespondentData(rData);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getData = async () => {
    try {
      const res = await getBannerValues(selectedMonths);
      const res2 = await getAttributesScores(selectedLocations,selectedMonths);
      console.log("Response", JSON.parse(res));
      setBannerValues(JSON.parse(res).BannerValues);
      setAttributes(JSON.parse(res2).attributes);
      console.log("Response2", JSON.parse(res2));
    } catch (error) {
      console.log(error);
    }
  };

  const getLocations = async () => {
    try {
      const res = await fetchLocations();
      console.log("Locations", JSON.parse(res));
      const temp = JSON.parse(res).locations.map((l) => ({
        value: l.location,
      }));
      setLocations(temp);
    } catch (error) {
      console.log(error);
    }
  };
  const getPersons = async () => {
    try {
      const res = await fetchVavaCarsPersons();
      console.log("Persons", JSON.parse(res));
      const temp = JSON.parse(res).Names.map((n) => ({
        value: n.vavacarsContactPerson,
      }));
      setPersons(temp);
    } catch (error) {
      console.log(error);
    }
  };

  const getRespondent = async () => {
    try {
      const res = await fetchRespondentName();
      console.log("Respondent", JSON.parse(res));
      const temp = JSON.parse(res).Names.map((r) => ({
        value: r.RespondentName,
      }));
      setRespondent(temp);
    } catch (error) {
      console.log(error);
    }
  };

  const getRespondentName = async (month) => {
    try {
      const res = await fetchRespondentName(month);
      console.log("Respondent", JSON.parse(res));
      const temp = JSON.parse(res).Names.map((r) => ({
        value: r.RespondentName,
      }));
      setRespondent(temp);
    } catch (error) {
      console.log(error);
    }
  };

  const getMonths = async () => {
    try {
      const res = await fetchMonth();
      console.log("MONTHS", JSON.parse(res));
      const temp = [{value:'All'}]
      JSON.parse(res).Months.forEach((m) => {
        temp.push({
          value: m.ExecutionMonth,
        })
      });
      setMonths(temp);
    }
    catch(error){
      console.log(error);
    }
  }

  const getValue = (item) => {
    const value = bannerValues.find(
      (el) => el.visitCenter?.toLocaleLowerCase() === item?.toLocaleLowerCase()
    )?.score;
    return value || 0;
  };

  const getBarValues = (data,item) => {
    return data
      .filter((el) => el.visitCenter === item)
      .map((el) => el.score);
  };

  const getCategories = (data) => {
    const temp = [];
    data.forEach((item) => {
      if (!temp.includes(item.type)) temp.push(item.type);
    });
    console.log(temp,"Temp")
    return temp;
  };

  return (
    <Layout>
      <Grid container className={clsx(classes.container, classes.paddingL)}>
        <Typography className={classes.heading} variant="h5">
          Months Wise Data
          <br />
        </Typography>
        <div style={{ width: "100%" }}>
          <div className={classes.dropdownContainer1}>
            {months && (
              <Dropdown
                options={months}
                selected={selectedMonths}
                label="Months"
                onChange={(e) => {
                  console.log(e.target.value)
                  
                  setSelectedMonths(e.target.value) 
                  getRespondentName(e.target.value)
                }}
              />
            )}
          </div>
          <div style={{ height: "100%", width: "100%" }}>
          {/* <Grid container>
          {locationData &&
            banners.map((item, idx) => (
              <Grid container xs={grids[idx]}>
                <BarChart
                  title={item}
                  data={getBarValues(locationData,item)}
                  color={[colors[idx]]}
                  type={"bar"}
                  width={350}
                  showLabels={idx === 0}
                  legend={"Achieved Score"}
                  categories={getCategories(locationData)}
                />
              </Grid>
            ))}
        </Grid> */}
     
          </div>
        </div>
      </Grid>
      <Grid container>
        <Grid container>
          {bannerValues &&
            banners.map((item, idx) => (
              <Grid container xs={3} style={{ padding: "1rem" }} key={idx}>
                <Card
                  iconColor={colors[idx]}
                  Icon={FaRegCalendarCheck}
                  title={item}
                  caption={getValue(item)}
                />
              </Grid>
            ))}
        </Grid>
        {bannerValues && (
          <Grid container justify="center">
            <BarChart
              title={""}
              data={banners.map((item, idx) => ({
                y: getValue(item),
                color: colors[idx],
              }))}
              // data={[
              //   { y: 13.5, color: "#81b941" },
              //   { y: 8.4, color: "#ffc000" },
              //   { y: 14.3, color: "#00b0f0" },
              //   { y: 7.1, color: "#c3e1fd" },
              // ]}
              type={"column"}
              width={1000}
              showLabels={true}
              categories={["VAVACars", "CarFirst", "Dealer", "Toyota Sure"]}
            />
          </Grid>
        )}
        <Grid container>
          {attributes &&
            banners.map((item, idx) => (
              <Grid container xs={grids[idx]}>
                <BarChart
                  title={item}
                  data={getBarValues(attributes, item)}
                  color={[colors[idx]]}
                  type={"bar"}
                  width={350}
                  showLabels={idx === 0}
                  legend={"Achieved Score"}
                  categories={getCategories(attributes)}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid container className={clsx(classes.container, classes.paddingL)}>
        <Typography className={classes.heading} variant="h5">
          Location Wise Data
          <br />
        </Typography>
        <div style={{ width: "100%" }}>
          <div className={classes.dropdownContainer1}>
            {locations && (
              <Dropdown
                options={locations}
                selected={selectedLocations}
                label="Locations"
                onChange={(e) => {
                  setLocationData(null)
                  setSelectedLocations(e.target.value) 
                }}
              />
            )}
          </div>
          <div style={{ height: "100%", width: "100%" }}>
          <Grid container>
          {locationData &&
            banners.map((item, idx) => (
              <Grid container xs={grids[idx]}>
                <BarChart
                  title={item}
                  data={getBarValues(locationData,item)}
                  color={[colors[idx]]}
                  type={"bar"}
                  width={350}
                  showLabels={idx === 0}
                  legend={"Achieved Score"}
                  categories={getCategories(locationData)}
                />
              </Grid>
            ))}
        </Grid>
     
          </div>
        </div>
      </Grid>

      <Grid container className={clsx(classes.container, classes.paddingL)}>
        <Typography className={classes.heading} variant="h5">
          Person Wise Data
          <br />
        </Typography>
        <div style={{ width: "100%" }}>
          <div className={classes.dropdownContainer1}>
            {persons && (
              <Dropdown
                options={persons}
                selected={selectedPersons}
                label="Persons"
                onChange={(e) =>
                  {setPersonsData(null)
                  setSelectedPersons(e.target.value)}}
              />
            )}
          </div>
          <div style={{ height: "100%", width: "100%" }}>
          {personsData&&<Grid container >
                <BarChart
                  title={'VavaCars'}
                  data={personsData.map(item => item.score)}
                  color={[colors[0]]}
                  type={"bar"}
                  width={500}
                  showLabels={true}
                  legend={"Achieved Score"}
                  categories={getCategories(personsData)}
                />
              </Grid>}
            </div>
        </div>
      </Grid>

      <Grid container className={clsx(classes.container, classes.paddingL)}>
        <Typography className={classes.heading} variant="h5">
          Respondent Wise Data
          <br />
        </Typography>
        <div style={{ width: "100%" }}>
          <div className={classes.dropdownContainer1}>
          {Respondent && (
              <Dropdown
                options={Respondent}
                selected={selectedRespondent}
                label="Respondent"
                onChange={(e) =>
                  {console.log(e.target.value)
                  setSelectedRespondent(e.target.value)}}
              />
            )}
          </div>
         
        </div>
      </Grid>
      <div>
          {image&& image.data.map(item=>{
            return <Grid container justify="space-between">
              <Grid container xs={3}><img src={item.Image1} width="auto" height={180} style={{marginBottom:'10px'}}/></Grid>
              <Grid container xs={3}><img src={item.Image2} width="auto" height={180} style={{marginBottom:'10px'}}/></Grid>
              <Grid container xs={3}><img src={item.Image3} width="auto" height={180} style={{marginBottom:'10px'}}/></Grid>
              
            </Grid>
            })}
          </div>
    </Layout>
  );
}

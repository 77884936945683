import http from "./api";

export const getAttributesScores = (location,month) =>
  new Promise((resolve, reject) =>
    http
      .get(`/getAttributesScores?location=${location}&month=${month}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

export const getAttributesScoresMPU = (location,month) =>
  new Promise((resolve, reject) =>
    http
      .get(`/getAttributesScoresMPU?location=${location}&month=${month}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

export const getBannerValues = (month) =>
  new Promise((resolve, reject) =>
    http
      .get(`/getBannerValues?month=${month}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

export const getBannerValuesMPU = (month) =>
  new Promise((resolve, reject) =>
    http
      .get(`/getBannerValuesMPU?month=${month}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

export const fetchVavaCarsPersons = () =>
  new Promise((resolve, reject) =>
    http
      .get("/getVavaCarPersons")
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

export const fetchLocations = () =>
  new Promise((resolve, reject) =>
    http
      .get("/getLocations")
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

export const fetchPersonWiseScore = (person, month) =>
  new Promise((resolve, reject) =>
    http
      .get(`/getPersonWiseScore?personName=${person}&month=${month}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

export const fetchLocationWiseScore = (location,month) =>
  new Promise((resolve, reject) =>
    http
      .get(`/getLocationWiseScore?location=${location}&month=${month}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

  export const fetchRespondentName = (month) =>
  new Promise((resolve, reject) =>
    http
      .get(`/getRespondentName?month=${month}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

  export const fetchRespondentImage = (month,Name) =>
  new Promise((resolve, reject) =>
    http
      .get(`/getRespondentImages?month=${month}&respondentName=${Name}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  );

  // export const fetchRespondent = () =>
  // new Promise((resolve, reject) =>
  //   http
  //     .get("/getRespondent")
  //     .then((res) => resolve(res.data))
  //     .catch((err) => reject(err))
  // );


export const fetchMonth = () => new Promise((resolve, reject) =>
http
  .get(`/getMonths`)
  .then((res) => resolve(res.data))
  .catch((err) => reject(err))
);
import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import Layout from "../../Layout/Layout";
import BarChart from "../../Components/BarChart";
import Dropdown from "../../Components/Dropdown/Dropdown";
import clsx from "clsx";
import Card from "../../Components/cardWithIcon/CardWithIcon";
import { FaRegCalendarCheck } from "react-icons/fa";
import {
  getAttributesScoresMPU,
  getBannerValuesMPU,
  fetchMonth
} from "../../Services/dashboard";
const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(2),
    border: "1px solid lightgrey",
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(5, 0),
    width: "100%",
  },
  heading: {
    padding: theme.spacing(3),
    borderTopRightRadius: theme.spacing(2),
    borderTopLeftRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: "white",
    width: "100%",
    background: theme.palette.primary.main,
  },
  dropdownContainer: {
    padding: theme.spacing(3),
    width: "20%",
  },
  dropdownContainer1: {
    padding: theme.spacing(3),
    width: "40%",
  },
  grid: {
    padding: theme.spacing(3),
  },
  paddingL: {
    //    marginLeft:theme.spacing(2)
  },
  paddingR: {
    //   marginRight:theme.spacing(2)
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  // const banners = ["VavaCars", "CarFirst", "Dealer", "Toyota Sure"];
  // const colors = ["#81b941", "#ffc000", "#00b0f0", "#c3e1fd"];
  const grids = [4, 3, 3, 2];
  const banners = ["VavaCars"];
  const colors = ["#81b941", "#ffc000", "#00b0f0", "#c3e1fd"];
  const [bannerValues, setBannerValues] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [months, setMonths] = useState([{value:"All"}]);
  const [selectedMonths, setSelectedMonths] = useState("All");
  useEffect(() => {
    getData();
    getMonths()
  }, []);

  useEffect(() => {
    if(selectedMonths){
      setBannerValues(null)
      setAttributes(null)
      getData();
    }
  },[selectedMonths])

  const getData = async () => {
    try {
      const res = await getBannerValuesMPU(selectedMonths);
      const res2 = await getAttributesScoresMPU("All",selectedMonths);
      console.log("Response", JSON.parse(res));
      setBannerValues(JSON.parse(res).BannerValues);
      setAttributes(JSON.parse(res2).attributes);
      console.log("Response2", JSON.parse(res2));
    } catch (error) {
      console.log(error);
    }
  };

  const getMonths = async () => {
    try {
      const res = await fetchMonth();
      console.log("MONTHS", JSON.parse(res));
      const temp = [{value:'All'}]
      JSON.parse(res).Months.forEach((m) => {
        temp.push({
          value: m.ExecutionMonth,
        })
      });
      setMonths(temp);
    }
    catch(error){
      console.log(error);
    }
  }

  const getValue = (item) => {
    const value = bannerValues.find(
      (el) => el.visitCenter?.toLocaleLowerCase() === item?.toLocaleLowerCase()
    )?.score;
    return value || 0;
   
  };

  const getBarValues = (item) => {
    return attributes
      .filter((el) => el.visitCenter === item)
      .map((el) => el.score);
  };

  const getCategories = () => {
    const temp = [];
    attributes.forEach((item) => {
      if (!temp.includes(item.type)) temp.push(item.type);
    });
    return temp;
  };

  return (
    <Layout>
      <Grid container className={clsx(classes.container, classes.paddingL)}>
        <Typography className={classes.heading} variant="h5">
          Months Wise Data
          <br />
        </Typography>
        <div style={{ width: "100%" }}>
          <div className={classes.dropdownContainer1}>
            {months && (
              <Dropdown
                options={months}
                selected={selectedMonths}
                label="Months"
                onChange={(e) => {
                  console.log(e.target.value)
                  
                  setSelectedMonths(e.target.value) 
                }}
              />
            )}
          </div>
          <div style={{ height: "100%", width: "100%" }}>
          {/* <Grid container>
          {locationData &&
            banners.map((item, idx) => (
              <Grid container xs={grids[idx]}>
                <BarChart
                  title={item}
                  data={getBarValues(locationData,item)}
                  color={[colors[idx]]}
                  type={"bar"}
                  width={350}
                  showLabels={idx === 0}
                  legend={"Achieved Score"}
                  categories={getCategories(locationData)}
                />
              </Grid>
            ))}
        </Grid> */}
     
          </div>
        </div>
      </Grid>
      <Grid container>
        <Grid container>
          {bannerValues &&
            banners.map((item, idx) => (
              <Grid container xs={3} style={{ padding: "1rem" }} key={idx}>
                <Card
                  iconColor={colors[idx]}
                  Icon={FaRegCalendarCheck}
                  title={item}
                  caption={getValue(item)}
                />
              </Grid>
            ))}
          {/* <Grid container xs={3} style={{ padding: "1rem" }}>
            <Card
              iconColor={"#ffc000"}
              Icon={FaRegCalendarCheck}
              title={"CarFirst"}
              caption={"8.4"}
            />
          </Grid>
          <Grid container xs={3} style={{ padding: "1rem" }}>
            <Card
              iconColor={"#00b0f0"}
              Icon={FaRegCalendarCheck}
              title={"Dealer"}
              caption={"14.3"}
            />
          </Grid>
          <Grid container xs={3} style={{ padding: "1rem" }}>
            <Card
              iconColor={"#c3e1fd"}
              Icon={FaRegCalendarCheck}
              title={"Toyota Sure"}
              caption={"7.1"}
            />
          </Grid> */}
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          {bannerValues && (
            <Grid xs={4} container justify="center">
              <BarChart
                title={""}
                data={banners.map((item, idx) => ({
                  y: getValue(item),
                  color: colors[idx],
                }))}
                // data={[
                //   { y: 13.5, color: "#81b941" },
                //   { y: 8.4, color: "#ffc000" },
                //   { y: 14.3, color: "#00b0f0" },
                //   { y: 7.1, color: "#c3e1fd" },
                // ]}
                type={"column"}
                // width={1000}
                showLabels={true}
                categories={["VAVACars"]}
              />
            </Grid>
          )}
          {/* <Grid container> */}
          {attributes &&
            banners.map((item, idx) => (
              <Grid container xs={6}>
                <BarChart
                  title={item}
                  data={getBarValues(item)}
                  color={[colors[idx]]}
                  type={"bar"}
                  showLabels={true}
                  legend={"Achieved Score"}
                  categories={getCategories()}
                />
              </Grid>
            ))}
          {/* <Grid container xs={3}>
            <BarChart
              title={"CarFirst"}
              data={[12.5, 8.4, 14.3, 7.1, 3.1, 20.7, 11.2, 15.9]}
              color={["#ffc000"]}
              type={"bar"}
              width={270}
              legend={"Achieved Score"}
            />
          </Grid>
          <Grid container xs={3}>
            <BarChart
              title={"Dealer"}
              data={[12.5, 8.4, 14.3, 7.1, 3.1, 20.7, 11.2, 15.9]}
              color={["#00b0f0"]}
              type={"bar"}
              width={270}
              legend={"Achieved Score"}
            />
          </Grid>
          <Grid container xs={3}>
            <BarChart
              title={"Toyota Sure"}
              data={[12.5, 8.4, 14.3, 7.1, 3.1, 20.7, 11.2, 15.9]}
              color={["#c3e1fd"]}
              type={"bar"}
              width={270}
              legend={"Achieved Score"}
            />
          </Grid>
        */}
        </Grid>
      </Grid>
    </Layout>
  );
}

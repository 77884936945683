import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      // main: "#2196f3",
      main: "#4EB373",
      // main: "#FFB319",
      light: "#5bb34e",
    },
    secondary: {
      // main: "#FB9902",
      main: "#4e8eb3",
      // light: "#E0E0E0",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"],
  },
  spacing: 5,
  shape: {
    borderRadius: 5,
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;

import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    minWidth: 80,
    minHeight: 30,
  },
}));

export default function Dropdown(props) {
  const { options, selected, onChange, label } = props;
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl
      margin="dense"
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel ref={inputLabel}>{label}</InputLabel>
      <Select
        value={selected}
        onChange={onChange}
        input={<OutlinedInput input labelWidth={labelWidth} />}
      >
        {options?.map((op) => (
          <MenuItem value={op.value}> {op.value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import React, { useState, useEffect } from "react";
import { useHistory, Redirect, Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/TextField";
import Loader from "../../Components/Loader/Loader";
import Error from "../../Components/Error/Error";
import { FaFacebookF } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import { FcGoogle } from "react-icons/fc";
// import { UserLogin } from "../../Redux/Actions/index";
import { validate } from "../../utils/inputValidation";
// import { getUser } from "../../Services/auth-services";
import { makeStyles } from "@material-ui/core/styles";
import Joi from "joi-browser";
import Image from "../../Assets/Images/bg.png";
import Toast from "../../Components/Toast/Toast";
import { userLogin } from "../../Services/auth";

const useStyles = makeStyles((theme) => ({}));

export default function Login2() {
  const classes = useStyles();
  //   const authData = useSelector((state) => state.auth);
  //   const loading = useSelector((state) => state.loading.loading);
  //   const LoginError = useSelector((state) => state.error.error);
  const history = useHistory();
  //   const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [user, setUser] = useState(null);
  const [toast, setToast] = useState({
    open: false,
    color: "",
    text: "",
  });

  //   useEffect(() => {
  // console.log("setotoa", LoginError);
  // if (LoginError) {
  //   setToast({
  //     open: true,
  //     color: "error",
  //     text: LoginError,
  //   });
  //   setTimeout(
  //     () =>
  //       setToast({
  //         open: false,
  //         color: "",
  //         text: "",
  //       }),
  //     3000
  //   );
  // }
  //   }, [LoginError]);

  useEffect(() => {
    // console.log(loading);
    // setUser(getUser());
    if (!error) {
      setError({});
      handleLogin();
    }
  }, [error]);

  //login scehma for joi validation
  const schema = {
    userName: Joi.string().required().label("Email"),
    password: Joi.string().required(),
  };

  //Handler for input fields, it will save the data from input fields into the state
  const onChange = (e) => {
    const { id, value } = e.target;
    let temp = { ...data };
    temp[id] = value;
    setData(temp);
  };

  //This function validate the data from input fields with the schema defined above
  //if there is any error it will set the error state accordingly and show the error and if there is no errors
  //it will execute the useEffect
  const onSubmit = (e) => {
    e.preventDefault();
    setError(validate(data, schema));
  };

  //This function will dispatch the redux action and store the user details in redux store
  const handleLogin = async () => {
    let temp = { ...data };
    setData({ userName: "", password: "" });
    try {
      setLoading(true);
      const { data } = await userLogin(temp);
      if (!data) {
        setToast({
          open: true,
          color: "error",
          text: "Invalid Username or Password!",
        });
      }
      console.log("Response", data);
      localStorage.setItem("vavaCarsToken", data);
      history.replace("/purchase-center");
    } catch (error) {
      console.log("Login Error", error);
    }
    setLoading(false);
  };

  return (
    <Grid
      container
      style={{
        background: "#4EB373",
        height: "97vh",
        padding: "3em",
        // opacity: "0.6",

        // backgroundImage: `url(${BG})`,
        // backgroundSize: "cover",
      }}
    >
      <Toast open={toast.open} color={toast.color} text={toast.text} />
      {/* {authData.userData[0] && <Redirect to="/projects" />} */}
      <Grid container style={{ background: "white", height: "100%" }}>
        <Grid
          container
          xs={6}
          style={{
            backgroundImage: `url(${Image})`,
            backgroundSize: "cover",
          }}
        ></Grid>

        <Grid container xs={6} style={{ padding: "1em 2em", height: "100%" }}>
          <Grid container style={{ height: "10%" }}>
            <Grid container justify="flex-end" alignItems="center">
              {/* <Typography variant="caption">
                {" "}
                Already have an account?
              </Typography> */}
              {/* <Button
                color="primary"
                variant="outlined"
                size="small"
                style={{ marginLeft: "15px", height: "70%" }}
              >
                <Link
                  style={{ textDecoration: "none", color: "#1565c0" }}
                  to="/register-company"
                >
                  SIGN UP
                </Link>
              </Button> */}
            </Grid>

            <Grid container style={{ margin: "1em 0" }}>
              <Typography variant="h5">Welcome</Typography>
              <Grid container />
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Login to your account
              </Typography>
            </Grid>
            <Grid container style={{ paddingRight: "2em" }} alignItems="center">
              <Input
                margin="dense"
                type="text"
                label="Email"
                id="userName"
                value={data.userName}
                style={{ width: "70%" }}
                onChange={onChange}
                variant="outlined"
              />
              {error && error.userName && <Error text={error.userName} />}
              <Input
                margin="dense"
                type="password"
                label="Password"
                id="password"
                value={data.password}
                style={{ width: "70%" }}
                variant="outlined"
                onChange={onChange}
              />
              {error && error.password && <Error text={error.password} />}
            </Grid>
            <Grid container>
              <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
                size="small"
                // size="large"
                style={{
                  marginTop: "2em",
                  padding: "0.5em 3.5em",
                  width: "191px",
                  color: "white",
                }}
              >
                {loading ? <Loader height={10} type={"ThreeDots"} /> : "Login"}
              </Button>
            </Grid>
            {/* <Grid container style={{ marginTop: "9em" }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Login with
                <Fab
                  size="small"
                  style={{
                    color: "blue",
                    background: "white",
                    fontSize: "1.1rem",
                    border: "1px solid grey",
                    margin: "0 0.5em 0 1em",
                  }}
                >
                  <FaFacebookF />
                </Fab>
                <Fab
                  size="small"
                  style={{
                    color: "blue",
                    background: "white",
                    fontSize: "1.1rem",
                    border: "1px solid grey",
                    margin: "0 0.5em",
                  }}
                >
                  <GrLinkedinOption />
                </Fab>
                <Fab
                  size="small"
                  style={{
                    background: "white",
                    fontSize: "1.1rem",
                    border: "1px solid grey",
                    margin: "0 0.5em",
                  }}
                >
                  <FcGoogle />
                </Fab>
              </Typography>
            </Grid>
           */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
